import React, { createRef } from 'react';
import Header from '../Header/Header';
import appAxios from '../../axios';
import { ReactMic } from 'react-mic';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import VideoInformation from './Information/Information';
import Scoreboard from './Scoreboard/Scoreboard';
import css from './Video.module.css';
import {Modal} from 'react-bootstrap';

import { history } from '../../_helpers/history';


class Video extends React.Component {
  videoRef = createRef();
  audioRef = createRef();

  constructor(props) {
    super(props);
    this.state = {
      activity: false,
      record: false,
      video_id: 0,
      saveVideo: false,
      audio: null,
      preview: false,
      information: null,
      startRecordingTimer: 3,
      videoOverlay: false,
      submitting: false,
      repeat_record: 0,
      date_start :null,
      view_video: 0,
      c_preview: 0,
      lgShow : true ,
      setLgShow: false,
      disableIniciarVideo: false
    }

  }

  componentDidMount(){
    this.videoRef.current.onpause = this.Pause;
    this.videoRef.current.onplay = this.PlayVideo;
  }

  Pause = () =>{

    if(this.state.saveVideo === true){
      this.audioRef.current.pause();
    }
  }

  PlayVideo = () =>{
    if(this.state.saveVideo === true){
      this.audioRef.current.play();
    }
  }


 ViewVideo = () => {
    this.setState({ view_video: this.state.view_video +1});
 }

  handleClickStartWork = () => {
    this.setState(prevState => ({activity: !prevState.activity }));
    setTimeout(() => { this.handleFindVideo(); }, 500);
  }

  handleFindVideo() {
    if (this.state.activity) {
      appAxios.get('/video-auditions', {
        params: { validate: this.state.activity }
      }).then(response => {
        const data = response.data;
        this.setState({information: data});
        this.setState({video_id: data.video.id });
        this.setState({ date_start: new Date()});
        this.videoRef.current.src = data.video.url;
        this.videoRef.current.preload = 'metadata';
        this.videoRef.current.muted = false;

        this.readyVideo();

        
      })
      .catch(error => {
        let message = error.message;
        if (error.response && error.response.data) {
          message = error.response.data.message;
          
          history.push('/registro-actividad');

        }
        NotificationManager.error(message);
      });

      //this.videoRef.current.onplay = this.ViewVideo;
    }
  }

  readyVideo = () =>{

    setTimeout(()=>{
      if(this.videoRef.current.ended){
        this.setState({disableIniciarVideo:true})
      }else{
        this.readyVideo();
      }
    },1000)

  }

  startRecording = () => {
    this.videoRef.current.muted = true;
    this.videoRef.current.currentTime = 0;
    this.videoRef.current.pause();
    this.audioRef.current.src = '';
    this.setState({ videoOverlay: true, saveVideo: false, audio: null, preview: false, repeat_record:this.state.repeat_record+1 });


    const interval = setInterval(() => {
      if (this.state.startRecordingTimer !== 0) {
        this.setState(prevState => ({ startRecordingTimer: prevState.startRecordingTimer - 1 }));
      }
      if (this.state.startRecordingTimer === 0) {
        clearInterval(interval);
        this.setState({ record: true, videoOverlay: false, startRecordingTimer: 3 });
        this.videoRef.current.play();
      };
    }, 1000);
  }

  stopRecording = () => {
    this.setState({ record: false });
    this.setState({ record: false });
    this.setState({ saveVideo: true });
  }

  onData = (recordedBlob) => {
    if (this.videoRef.current.ended) {
      this.setState({ saveVideo: true });
      this.stopRecording();
    }


  }

  onStop = (recordedBlob) => {
    this.setState({ record: false });

    this.setState({ audio: recordedBlob });
    this.setState({ preview: true });
    this.audioRef.current.src = recordedBlob.blobURL;
    this.videoRef.current.pause();

  }

  onPrewiew = () => {
    this.videoRef.current.muted = false;
    this.videoRef.current.volume = 0.1;
    this.videoRef.current.currentTime = 0;
    this.videoRef.current.play();

    this.audioRef.current.currentTime = 0;
    this.audioRef.current.play();

    this.setState({ c_preview: this.state.c_preview +1});

  }



  onSaveVideo = () => {
    const fileReader = new FileReader();
    this.setState({ submitting: true });

    fileReader.addEventListener('load', () => {


      appAxios.post(`/video/${this.state.video_id}`, { 
        audio: fileReader.result,
        c_preview: this.state.c_preview,
        repeat_record: this.state.repeat_record,
        view_video: this.state.view_video,
        date_start: this.state.date_start,
        date_end: new Date(),
        metrics: 1
      
      })
        .then(response => {
          NotificationManager.success('Se Guardo correctamente el video');
          this.setState({ record: false, saveVideo: false, audio: null, preview: false, submitting: false, repeat_record: 0, date_start: null, view_video: 0,c_preview:0,disableIniciarVideo:false });


          this.handleFindVideo();
        })
        .catch(error => {
          let message = error.message;
          if (error.response && error.response.data) {
            message = error.response.data.message;
          }
          NotificationManager.error(message);
          this.setState({ submitting: false});
        });
    });

    fileReader.readAsDataURL(this.state.audio.blob);
  }

  render () {
    const { information, activity, record, preview, saveVideo, startRecordingTimer, videoOverlay, submitting,lgShow, disableIniciarVideo } = this.state;

    return (
      <div className='min-h-screen custom-bg-secondary'>

        
        <Header />
        <NotificationContainer />

        <Modal   dialogClassName={css.modal90} show={lgShow} onHide={() => this.setState({lgShow: false})}  aria-labelledby="example-modal-sizes-title-lg">
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Tutorial
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <iframe width="100%" height="566" src="https://www.youtube.com/embed/nQPaNz-PAXc"  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" title='audicion'>

          </iframe>
          </Modal.Body>
        </Modal>

        <div className='d-flex pr-4'>
          <VideoInformation
            info={information}
            activity={activity}
            activateWork={this.handleClickStartWork} />

          <div className={css.Content}>
            <Scoreboard info={information ? information.result_match : null}/>
            <div className={css['Video-container']}>
              {videoOverlay && (
                <div className={css.Overlay}>
                  <p className={`${css.OverlayTitle} text-white`}>Tu relato inicia en:</p>
                  <p className={`${css.OverlayNumber} text-white`}>{startRecordingTimer}</p>
                </div>
              )}

              <video className={css.Video} controls ref={this.videoRef} />
            </div>

            <footer className='py-2'>
              <div className={`${css.Wave} ${!activity ? 'd-none' : 'd-flex'}`}>
                {!record && <div className={css.WaveBgc} />}
                <ReactMic record={this.state.record}
                  className={css.SoundWave}
                  onStop={this.onStop}
                  onData={this.onData}
                  strokeColor="#FFF"
                  backgroundColor="#253a60"
                  mimeType="audio/mp3" />
                <audio controls ref={this.audioRef} className="d-none" />
              </div>

              <div className={`pt-2 align-items-center ${!activity ? 'd-none' : 'd-flex'}`}>
                { !record && (
                  <button type='button'
                    className={`${css.Start} shadow-dp2`}
                    disabled={!disableIniciarVideo}
                    onClick={() => this.startRecording()}>
                      <div className="d-flex align-items-center"><span className={css.StartDot} />{ this.state.repeat_record === 0 ? 'INICIAR RELATO' :'RELATAR DE NUEVO' }</div>
                  </button>
                )}
               

                { preview && (
                  <button className={`${css.Preview} shadow-dp2 ml-3`}
                    onClick={this.onPrewiew}>
                    <div className="d-flex align-items-center">Ver y escuchar lo que relaté</div>
                  </button>
                )}

                { saveVideo && (
                  <button className={`${css.Save} shadow-dp2 ml-auto`}
                    disabled={submitting}
                    onClick={this.onSaveVideo}>
                    <div className="d-flex align-items-center">{submitting ? 'Enviando...' : 'Guardar' }</div>
                  </button>
                )}
              </div>
            </footer>
          </div>
        </div>
      </div>
    )
  }
}

export default Video;
